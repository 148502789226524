.our-story {
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  padding: 2rem 0 2rem;
}

.our-story-description {
  grid-column: 1;
  font-family: 'Pigiarniq', sans-serif;
  font-weight: normal;
}

.our-story-description h2 {
  line-height: 1;
  font-family: 'Pigiarniq', sans-serif;
  font-weight: normal;
  font-size: 1.75rem;
}

.our-story-description p {
  padding-top: 1.5rem;
}

.our-story-personal {
  grid-column: 2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.our-story-personal img {
  max-width: 55%;
  height: auto;
  border-radius: 9rem;
  object-fit: cover;
}

@media (max-width: 915px) {
  .our-story {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .our-story-personal {
    grid-column: 1;
    position: static;
    justify-content: space-evenly;
    flex-direction: row;
    height: unset;
  }

  .our-story-personal img {
    position: static;
    max-width: 50%;
    height: 26rem;
    width: unset;
    border-radius: 12rem;
  }
}

@media (max-width: 715px) {
  .our-story {
    grid-template-rows: repeat(3, auto);
  }

  .our-story-personal {
    flex-direction: column;
  }

  .our-story-personal img {
    max-width: 100%;
  }
}

@media (max-width:576px) {
  .our-story {
    padding: 1rem 0;
  }
}
