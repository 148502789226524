.container-contact {
  min-height: calc(100vh - 6.25rem - 6rem);
  max-width: 85rem;
  margin: 0rem auto;
}

.container-contact h2 {
  text-align: center;
  width: 100%;
  padding: 6rem 2rem 2rem 2rem;
  color: #272e2e;
  height: max-content;
  font-family: 'Pigiarniq', sans-serif;
  font-size: 2rem;
}

.container-contact p {
  text-align: center;
  width: 100%;
  padding: 0rem 2rem 2rem 2rem;
  color: #272e2e;
  font-family: 'Pigiarniq', sans-serif;
  font-size: 1rem;
}

.container-contact form {
  padding: 0 2rem 2rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, minmax(12.5rem, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.container-contact form button {
  width: max-content;
  margin: 0 auto;
  grid-column: 1 / span 2;
}

.form-field {
  font-family: 'Pigiarniq', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.form-field p {
  font-size: 1rem;
  color: red;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: url("/src/components/pages/Home/assets/arrowDown.svg");
  font-weight: 600;
  height: 1.5rem;
  width: 1.5rem;
  top: 0.5rem;
  right: 0.625rem;
  position: absolute;
}

.container-contact button:disabled,
.container-contact button[disabled] {
  background-color: #edefee;
  border-color: #edefee;
  color: #adafae;
  cursor: not-allowed;
}

.confirmed-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.75rem 0;
  min-height: calc(100vh - 6.25rem - 6rem);
}

.confirmed-contact h2 {
  padding: 1.5rem 0rem;
  color: #272e2e;
  height: max-content;
  font-family: 'Pigiarniq', sans-serif;
  font-size: 2rem;
}

.confirmed-contact p {
  padding-top: 0.25rem;
}

.contact-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  gap: 0.5rem;
  padding: 1.5rem 0rem;
}

@media (max-width: 768px) {
  .container-contact form {
    grid-template-columns: repeat(1, 1fr);
  }

  .container-contact h2 {
    padding: 0rem 2rem;
  }

  .container-contact form button {
    grid-column: 1 / span 1;
  }

  .confirmed-contact h2 {
    text-align: center;
    font-size: 1.5rem;
  }
}

.checkbox-group {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  gap: 0.5rem;
}

.terms-checkbox {
  width: 50%;
  height: 1.5rem;
}

.link-permissions {
  width: 50%;
  text-decoration: underline;
  cursor: pointer;
  color: darkblue;
}
