.personal-skill {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0 20px #d9d9d9;
  text-align: center;
}

.skill-item {
  flex: 1 1 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
  position: relative;
}

.skill-item img {
  max-width: 80px;
  height: auto;
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease;
}

.skill-item h4 {
  font-size: 0.9rem;
  color: #f9f9f9;
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s ease, color 0.3s ease;
}

.skill-item:hover img {
  transform: scale(1.1);
}

.skill-item:hover h4 {
  opacity: 1;
  color: #333;
}
