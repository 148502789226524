.lenguage-container-skills {
  padding-top: 1.875rem;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 2rem 0;
}

.lenguage-container-skills .container {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto auto;
  row-gap: 1.5rem;
}

.lenguage-container-skills h2 {
  grid-row: 1;
  color: #272e2e;
  line-height: 1;
  font-family: 'Pigiarniq', sans-serif;
  font-weight: normal;
  font-size: 1.75rem;
}

@media (max-width:576px) {
  .lenguage-container-skills {
    padding: 1rem 0;
  }
}