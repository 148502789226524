.hero {
  padding-top: 1.875rem;
  position: relative;
  padding: 2rem 0;
}

.hero .container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.hero-information {
  grid-column: 1;
}

.hero-information h1 {
  color: 272e2e;
  font-size: 3.75rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  font-family: 'Pigiarniq', sans-serif;
}

.hero-information h2 {
  color: #272e2e;
  font-size: 2rem;
  height: 2.25rem;
  line-height: 2.25rem;
  padding: 1.5rem;
  font-family: 'Pigiarniq', sans-serif;
}

.hero-information .button-primary {
  display: inline-block;
  margin-top: 2rem;
}

.hero-information .button-primary:hover,
.hero-information .button-primary:focus {
  border-color: #f4ce14;
}

@media (max-width: 792px) {
  .hero .container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .hero-information {
    grid-row: 1;
  }
}

@media (max-width: 576px) {
  .hero {
    padding: 1.5rem 0;
  }

  .hero-information h1 {
    font-size: 3rem;
  }

  .hero-information h2 {
    font-size: 1.75rem;
    padding: 1rem;
  }
}
