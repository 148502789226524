.site-footer {
  position: relative;
  background-color: #f9f9f9;
  color: #272e2e;
  padding: 1rem 6%;
  height: 6rem;
  display: grid;
  gap: 2rem;
  justify-content: space-evenly;
  grid-template-columns: repeat(3, minmax(6rem, max-content));
  border-top: 0.15rem solid #272e2e;
}

.site-footer-logo {
  display: flex;
  align-items: center;
}

.logo {
  width: 8rem;
}

.site-footer h4 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.site-footer a {
  color: #272e2e;
}

.site-footer a:hover,
.site-footer a:focus {
  color: #f4ce14;
}

.site-footer-nav ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.site-footer-nav li {
  margin-bottom: 0.25rem;
}

.site-footer-contact svg {
  margin-right: 0.5rem;
}

.site-footer-contact p {
  margin-bottom: 0.25rem;
}

.site-footer-social svg {
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .site-footer {
    height: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .site-footer-logo {
    width: 8rem;
  }
}
