@font-face {
  font-family: "Pigiarniq";
  src: url("./components/outils/fonts/Pigiarniq.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Pigiarniq', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #272e2e;
  background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: max-content;
  background-color: #f9f9f9;
  z-index: 999;
}

main {
  padding-top: 6.25rem;
  min-height: calc(100vh - 6.25rem - 18rem);
}

a {
  text-decoration: none;
  color: #272e2e;
}

h1,
h2 {
  line-height: 1;
  font-family: "Markazi Text";
  font-weight: normal;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2.5rem;
}

label {
  width: fit-content;
  cursor: pointer;
}

input,
select {
  width: 100%;
  height: 2.5rem;
  padding: 0 0.5rem;
  outline: none;
  border: 1px solid #e0e0e0;
  border-radius: 0.375rem;
  background: #ffffff;
  color: #272e2e;
  cursor: pointer;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

input:focus,
select:focus {
  border: 1px solid #272e2e;
}

label {
  font-weight: bold;
}

button {
  border: none;
  cursor: pointer;
}

img {
  max-width: 100%;
}

.container {
  max-width: 61.75rem;
  width: 90%;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.button-primary, .button-submit {
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  background-color: #f4ce14;
  color: #272e2e;
  font-weight: bold;
  font-size: 0.9rem;
}

@media (max-width: 576px) {
  .button-primary {
    padding: 0.30rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.8rem
  }
}

.button-primary:hover,
.button-primary:focus {
  background-color: #272e2e;
  border-color: #272e2e;
  color: #ffffff;
}
