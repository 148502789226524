.portfolio-projects {
  padding: 2rem 0;
}

.portfolio-projects-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
}

.slick-slide {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.portfolio-projects-header h2 {
  line-height: 1;
  font-family: 'Pigiarniq', sans-serif;
  font-weight: normal;
  font-size: 1.75rem;
}

@media (max-width: 960px) {
  .portfolio-projects {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
  }

  .portfolio-projects-header {
    grid-column: 1 / 3;
  }
}

@media (max-width: 692px) {
  .portfolio-projects {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }

  .portfolio-projects-header {
    grid-column: 1 / 2;
  }

  .slick-slider .slick-next {
    right: -15px !important;
  }
  .slick-slider .slick-prev {
    left: -15px !important;
  }
}

@media (max-width: 576px) {
  .portfolio-projects {
    padding: 1rem 0;
  }

  .portfolio-projects-header {
    align-items: center;
  }

  .portfolio-projects-header h2 {
    font-size: 1.75rem;
  }
}

@media (max-width: 449px) {
  .portfolio-projects-header a {
    width: max-content;
  }
}

@media (max-width: 284px) {
  .portfolio-projects-header h2 {
    letter-spacing: -0.5px;
  }
}
