.project-card {
  grid-column: auto;
  grid-row: 2;
  min-height: 31.25rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #f9f9f9;
  box-shadow: 0 0 20px #d9d9d9;
  transition: transform 0.3s ease;
}

.project-card-image {
  position: relative;
  height: 12.5rem;
  overflow: hidden;
}

.project-card:hover .project-card-image img {
  transform: scale(1.08);
}

.project-card-image img {
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12.5rem;
  object-fit: contain;
  object-position: center;
  transition: all linear 300ms;
}

.project-card-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-card-image:hover .project-card-image-overlay {
  opacity: 1;
}

.project-card-image-overlay-link {
  color: #fff;
  font-size: 1.15rem;
  text-decoration: none;
}

.project-card-header,
.project-card-body-footer {
  padding: 0 1rem;
}

.project-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.project-card-header h3 {
  font-size: 1.125rem;
}

.project-card-header span {
  font-weight: 700;
  color: #ee9972;
}

.project-card-body-footer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.project-card-body-footer a {
  font-weight: bold;
}

.project-card-body-footer svg {
  margin-left: 0.5rem;
}

@media (max-width: 960px) {
  .project-card {
    grid-row: auto;
    max-width: 24rem;
  }
}

.project-card-icons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
}

.project-card a:hover,
.project-card a:focus {
  color: #f4ce14;
}

.project-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  margin-top: 0.5rem;
}

.project-badge {
  background-color: #f0f0f0;
  color: #272e2e;
  padding: 0.3rem 0.8rem;
  border-radius: 0.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.project-badge:hover {
  background-color: #e0e0e0;
  color: #1a1a1a;
}



